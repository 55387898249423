/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, Typography } from '@mui/material';

import { TrialFilter } from '@components/TrialFilter';
import { GrowerFilter } from '@components/GrowerFilter';
import { FieldsList } from '@components/Planner/FieldsList';
import { fieldCard } from '@components/Planner/FieldsList/FieldsList';
import { ProtocolFilter } from '@components/ProtocolFilter';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetProtocolName } from '@hooks/useGetProtocolName';
import { useGetTrial } from '@hooks/useGetTrial';
import { useGetGrower } from '@hooks/useGetGrower';
import { useGetClient } from '@hooks/useGetClient';
import { RootState } from '@state-mgmt/store';
import { setFields } from '@state-mgmt/slices/downloader-slice';
import { styles } from './styles';
import { useGetMyFieldVisits } from '@hooks/useGetMyFieldVisits';
import useMyVisitFilterState from '@components/MyVisitFilters/useMyVisitFilterState';
import MyVisitFilters from '@components/MyVisitFilters/MyVisitFilters';

const NewPlanWizardSecondStep = () => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const [fieldsList, setFieldsList] = useState<fieldCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedProtocol, setSelectedProtocol] = useState<string>('all');
  const [selectedGrower, setSelectedGrower] = useState<string | undefined>();
  const { fields } = useSelector((state: RootState) => state.trial);
  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const { fields: selectedFields } = useSelector((state: RootState) => state.downloader);
  const [tab, setTab] = useState<number>(0);
  const getProtocolName = useGetProtocolName();
  const getTrial = useGetTrial();
  const getGrower = useGetGrower();
  const getClient = useGetClient();
  const dispatch = useDispatch();

  const [hasOpenedMyVisits, setHasOpenMyVisits] = useState<boolean>(false);

  const [getMyVisitsState, getMyFieldVisits] = useGetMyFieldVisits();

  useEffect(() => {
    if (tab === 1) {
      setHasOpenMyVisits(true);
    }
  }, [tab]);

  useEffect(() => {
    if (hasOpenedMyVisits && clientId) {
      getMyFieldVisits(clientId);
    }
  }, [hasOpenedMyVisits, clientId, getMyFieldVisits]);

  const myVisitFilterState = useMyVisitFilterState({ visits: getMyVisitsState.data?.visits });

  const myVisitsSource = useMemo((): fieldCard[] => {
    if (!myVisitFilterState.filteredVisits || !clientId) return [];
    const client = getClient(clientId);

    const visits: fieldCard[] = [];

    myVisitFilterState.filteredVisits.forEach(v => {
      if (!visits.some(existing => existing.fieldId === v.field_id && existing.protocolId === v.field_id)) {
        visits.push({
          clientId: client?.id || '',
          clientName: client?.name || '',
          trialId: v.trial_id,
          trialName: v.trial_name,
          fieldId: v.field_id,
          fieldName: v.field_name,
          protocolId: v.protocol_id,
          protocolName: v.protocol_name,
          growerId: v.grower_id,
          growerName: v.grower_name,
          mySteps: [{ stepId: v.step_id, label: v.label }]
        });
      } else {
        const visit = visits.find(existing => existing.fieldId === v.field_id && existing.protocolId === v.protocol_id);

        if (visit && visit.mySteps && !visit.mySteps.some(existing => existing.stepId === v.step_id)) {
          visit.mySteps.push({ stepId: v.step_id, label: v.label });
        }
      }
    });

    return visits;
  }, [myVisitFilterState.filteredVisits, clientId, getClient]);

  const source = useMemo((): fieldCard[] => {
    if (!clientId || !trialId) return [];

    if (!isLoading) {
      return fields.map(field => {
        const grower = getGrower(field.owner_id);
        const client = getClient(clientId);
        return {
          clientId: client?.id || '',
          clientName: client?.name || '',
          trialId,
          trialName: getTrial(trialId)?.name || '',
          fieldId: field.field_id,
          fieldName: field.name,
          protocolId: field.protocol_id,
          protocolName: getProtocolName(field.protocol_id) || '',
          growerId: field.owner_id,
          growerName: grower ? `${grower.first_name} ${grower.last_name}` : ''
        };
      });
    }
    return [];
  }, [isLoading, clientId, fields, getClient, getGrower, getProtocolName, getTrial, trialId]);

  useEffect(() => setFieldsList(source), [source]);

  useEffect(() => {
    setFieldsList(
      source
        .filter(row => {
          const conditionProtocolFilter = selectedProtocol !== 'all' ? selectedProtocol === row.protocolId : true;
          const conditionGrowerFilter = selectedGrower ? selectedGrower === row.growerId : true;
          return conditionProtocolFilter && conditionGrowerFilter;
        })
        .sort((a: fieldCard, b: fieldCard) => a.fieldName.localeCompare(b.fieldName))
    );
  }, [selectedProtocol, selectedGrower, source]);

  const handleOnAddField = (field: fieldCard) => {
    dispatch(setFields([...selectedFields, field]));
  };

  const handleRemoveField = (field: fieldCard) => {
    const newFields = selectedFields.filter(
      ({ trialId, protocolId, fieldId }) => !(trialId === field.trialId && protocolId === field.protocolId && fieldId === field.fieldId)
    );
    dispatch(setFields(newFields));
  };

  return (
    <div css={styles.container(theme)}>
      <LoadingOverlay spinner active={isLoading || getMyVisitsState.isLoading} styles={{ wrapper: styles.loadingOverlayContent }}>
        <div>
          <Tabs centered value={tab} onChange={(_, value) => setTab(value)} aria-label="My Visits Tabs">
            <Tab label={t('planner.create.field-search-tab')} />
            <Tab label={t('planner.create.my-visits-tab')} />
          </Tabs>
        </div>
        <Typography color="text.primary" css={styles.title}>
          {tab === 0 ? t('planner.create.search-title') : t('planner.create.my-visits-title')}
        </Typography>

        {tab === 0 ? (
          <>
            <TrialFilter onTrialSwitching={isSwitching => setIsLoading(isSwitching)} />
            <ProtocolFilter onChange={protocolId => setSelectedProtocol(protocolId)} />
            <GrowerFilter selectedProtocolId={selectedProtocol} onChange={growerId => setSelectedGrower(growerId)} />
          </>
        ) : (
          <MyVisitFilters state={myVisitFilterState} />
        )}

        <FieldsList fields={tab === 0 ? fieldsList : myVisitsSource} onAddField={handleOnAddField} onRemoveField={handleRemoveField} />
      </LoadingOverlay>
    </div>
  );
};

export default NewPlanWizardSecondStep;
