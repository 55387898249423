import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';

import { signInApi } from '@services/fdc';
import { getUserDeviceInfo } from '@utils/getUserDeviceInfo';

export const useSignIn = () =>
  useAsyncCallback(
    useCallback(async (username: string, password: string) => {
      try {
        const deviceInfo = await getUserDeviceInfo().catch(err => {
          console.warn('Unable to fetch user device info', err);
        });

        const data = await signInApi({ username, password, ...(deviceInfo ? deviceInfo : {}) });
        return data;
      } catch (error) {
        console.error(error);
      }
    }, [])
  );
