export type AppConfig = {
  REACT_APP_ENV: string;
  REACT_APP_APP_VERSION: string;
  REACT_APP_API_URL: string;
  REACT_APP_SENTRY_DNS: string;
  REACT_APP_CLIENT_SERVICE_URL: string;
  REACT_APP_FIELD_SERVICE_URL: string;
  REACT_APP_MDI_SERVICE_URL: string;
  REACT_APP_SOILS_SERVICE_URL: string;
  REACT_APP_PROTOCOL_SERVICE_URL: string;
  REACT_APP_TRIAL_SERVICE_URL: string;
  REACT_APP_USER_SERVICE_URL: string;
  REACT_APP_MAPBOX_ACCESS_TOKEN: string;
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string;
  REACT_APP_FIREBASE_VAPID_KEY: string;
  REACT_APP_ACCURACY_GOOD: number;
  REACT_APP_ACCURACY_FAIR: number;
  REACT_APP_ACCURACY_POOR: number;
  REACT_APP_NOTE_REGEX: string;
  REACT_APP_INVISION_LEARNING_GUIDE_URL: string;
};

const appVersion = '0.3.88';
const noteRegex = "^([À-ÿA-Za-z0-9'/%&#()_¿?:+,.|=*;<>~!@$œŒ«»ª -]|\r\n|\r|\n)+$";
const invisionLearningGuideUrl = 'https://docs.prod.intent.ag/books/invision-learning-guide/chapter/invision-mobile';

const DEV_CONFIG = {
  REACT_APP_ENV: 'dev',
  REACT_APP_APP_VERSION: appVersion,
  REACT_APP_SENTRY_DNS: '',
  REACT_APP_API_URL: 'http://localhost:3010/api',
  REACT_APP_CLIENT_SERVICE_URL: 'https://client.staging.intent.ag',
  REACT_APP_FIELD_SERVICE_URL: 'https://field.staging.intent.ag',
  REACT_APP_MDI_SERVICE_URL: 'https://mdi.staging.intent.ag',
  REACT_APP_SOILS_SERVICE_URL: 'https://soils.staging.intent.ag',
  REACT_APP_PROTOCOL_SERVICE_URL: 'https://protocol.staging.intent.ag',
  REACT_APP_TRIAL_SERVICE_URL: 'https://trial-service.staging.intent.ag',
  REACT_APP_USER_SERVICE_URL: 'https://user.staging.intent.ag',
  REACT_APP_MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibWF0dGluMTB0IiwiYSI6ImNraHcyeXoyaDE5b3gyeW1hZGRpbWFhMmYifQ.dmuzo2FcaojK5wjK-8EqhQ',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyAmHJpMHZq-4XwKU0kZjcbT7QTeDhIYdaA',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'apogee-development.firebaseapp.com',
  REACT_APP_FIREBASE_PROJECT_ID: 'apogee-development',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'apogee-development.appspot.com',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '954427512329',
  REACT_APP_FIREBASE_APP_ID: '1:954427512329:web:4fa6f557c54af8a7b18ad8',
  REACT_APP_FIREBASE_MEASUREMENT_ID: '',
  REACT_APP_FIREBASE_VAPID_KEY: 'BPp3l6ufqBq-a2ew13k6d1TOtG0eSKW2mKi_q5ETlkRzAu3zVHFe0_W4n3LmkfRZfQ-R-lftN_khVghBNVrDDpc',
  REACT_APP_ACCURACY_GOOD: 10,
  REACT_APP_ACCURACY_FAIR: 20,
  REACT_APP_ACCURACY_POOR: 30,
  REACT_APP_NOTE_REGEX: noteRegex,
  REACT_APP_INVISION_LEARNING_GUIDE_URL: invisionLearningGuideUrl
};

const STAGING_CONFIG = {
  REACT_APP_ENV: 'staging',
  REACT_APP_APP_VERSION: appVersion,
  REACT_APP_SENTRY_DNS: '',
  REACT_APP_API_URL: 'https://trials-mobile-api.staging.intent.ag/api',
  REACT_APP_CLIENT_SERVICE_URL: 'https://client.staging.intent.ag',
  REACT_APP_FIELD_SERVICE_URL: 'https://field.staging.intent.ag',
  REACT_APP_MDI_SERVICE_URL: 'https://mdi.staging.intent.ag',
  REACT_APP_SOILS_SERVICE_URL: 'https://soils.staging.intent.ag',
  REACT_APP_PROTOCOL_SERVICE_URL: 'https://protocol.staging.intent.ag',
  REACT_APP_TRIAL_SERVICE_URL: 'https://trial-service.staging.intent.ag',
  REACT_APP_USER_SERVICE_URL: 'https://user.staging.intent.ag',
  REACT_APP_MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibWF0dGluMTB0IiwiYSI6ImNraHcyeXoyaDE5b3gyeW1hZGRpbWFhMmYifQ.dmuzo2FcaojK5wjK-8EqhQ',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyAmHJpMHZq-4XwKU0kZjcbT7QTeDhIYdaA',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'apogee-development.firebaseapp.com',
  REACT_APP_FIREBASE_PROJECT_ID: 'apogee-development',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'apogee-development.appspot.com',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '954427512329',
  REACT_APP_FIREBASE_APP_ID: '1:954427512329:web:4fa6f557c54af8a7b18ad8',
  REACT_APP_FIREBASE_MEASUREMENT_ID: '',
  REACT_APP_FIREBASE_VAPID_KEY: 'BPp3l6ufqBq-a2ew13k6d1TOtG0eSKW2mKi_q5ETlkRzAu3zVHFe0_W4n3LmkfRZfQ-R-lftN_khVghBNVrDDpc',
  REACT_APP_ACCURACY_GOOD: 10,
  REACT_APP_ACCURACY_FAIR: 20,
  REACT_APP_ACCURACY_POOR: 30,
  REACT_APP_NOTE_REGEX: noteRegex,
  REACT_APP_INVISION_LEARNING_GUIDE_URL: invisionLearningGuideUrl
};

const PREVIEW_CONFIG = {
  REACT_APP_ENV: 'preview',
  REACT_APP_APP_VERSION: appVersion,
  REACT_APP_SENTRY_DNS: '',
  REACT_APP_API_URL: 'https://trials-mobile-api.preview.intent.ag/api',
  REACT_APP_CLIENT_SERVICE_URL: 'https://client.preview.intent.ag',
  REACT_APP_FIELD_SERVICE_URL: 'https://field.preview.intent.ag',
  REACT_APP_MDI_SERVICE_URL: 'https://mdi.preview.intent.ag',
  REACT_APP_SOILS_SERVICE_URL: 'https://soils.preview.intent.ag',
  REACT_APP_PROTOCOL_SERVICE_URL: 'https://protocol.preview.intent.ag',
  REACT_APP_TRIAL_SERVICE_URL: 'https://trial-service.preview.intent.ag',
  REACT_APP_USER_SERVICE_URL: 'https://user.preview.intent.ag',
  REACT_APP_MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibWF0dGluMTB0IiwiYSI6ImNraHcyeXoyaDE5b3gyeW1hZGRpbWFhMmYifQ.dmuzo2FcaojK5wjK-8EqhQ',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyAmHJpMHZq-4XwKU0kZjcbT7QTeDhIYdaA',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'apogee-development.firebaseapp.com',
  REACT_APP_FIREBASE_PROJECT_ID: 'apogee-development',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'apogee-development.appspot.com',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '954427512329',
  REACT_APP_FIREBASE_APP_ID: '1:954427512329:web:4fa6f557c54af8a7b18ad8',
  REACT_APP_FIREBASE_MEASUREMENT_ID: '',
  REACT_APP_FIREBASE_VAPID_KEY: 'BPp3l6ufqBq-a2ew13k6d1TOtG0eSKW2mKi_q5ETlkRzAu3zVHFe0_W4n3LmkfRZfQ-R-lftN_khVghBNVrDDpc',
  REACT_APP_ACCURACY_GOOD: 10,
  REACT_APP_ACCURACY_FAIR: 20,
  REACT_APP_ACCURACY_POOR: 30,
  REACT_APP_NOTE_REGEX: noteRegex,
  REACT_APP_INVISION_LEARNING_GUIDE_URL: invisionLearningGuideUrl
};

const PRODUCTION_CONFIG = {
  REACT_APP_ENV: 'production',
  REACT_APP_APP_VERSION: appVersion,
  REACT_APP_SENTRY_DNS: 'https://b91e36a00a934a8c89891c63993028f7@o450992.ingest.sentry.io/4505567771754496',
  REACT_APP_API_URL: 'https://trials-mobile-api.prod.intent.ag/api',
  REACT_APP_CLIENT_SERVICE_URL: 'https://client.prod.intent.ag',
  REACT_APP_FIELD_SERVICE_URL: 'https://field.prod.intent.ag',
  REACT_APP_MDI_SERVICE_URL: 'https://mdi.prod.intent.ag',
  REACT_APP_SOILS_SERVICE_URL: 'https://soils.prod.intent.ag',
  REACT_APP_PROTOCOL_SERVICE_URL: 'https://protocol.prod.intent.ag',
  REACT_APP_TRIAL_SERVICE_URL: 'https://trial-service.prod.intent.ag',
  REACT_APP_USER_SERVICE_URL: 'https://user.prod.intent.ag',
  REACT_APP_MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibWF0dGluMTB0IiwiYSI6ImNraHcyeXoyaDE5b3gyeW1hZGRpbWFhMmYifQ.dmuzo2FcaojK5wjK-8EqhQ',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyAmHJpMHZq-4XwKU0kZjcbT7QTeDhIYdaA',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'apogee-development.firebaseapp.com',
  REACT_APP_FIREBASE_PROJECT_ID: 'apogee-development',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'apogee-development.appspot.com',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '954427512329',
  REACT_APP_FIREBASE_APP_ID: '1:954427512329:web:4fa6f557c54af8a7b18ad8',
  REACT_APP_FIREBASE_MEASUREMENT_ID: '',
  REACT_APP_FIREBASE_VAPID_KEY: 'BPp3l6ufqBq-a2ew13k6d1TOtG0eSKW2mKi_q5ETlkRzAu3zVHFe0_W4n3LmkfRZfQ-R-lftN_khVghBNVrDDpc',
  REACT_APP_ACCURACY_GOOD: 10,
  REACT_APP_ACCURACY_FAIR: 20,
  REACT_APP_ACCURACY_POOR: 30,
  REACT_APP_NOTE_REGEX: noteRegex,
  REACT_APP_INVISION_LEARNING_GUIDE_URL: invisionLearningGuideUrl
};

let config: AppConfig = DEV_CONFIG;

if (typeof window !== 'undefined') {
  const host = window?.location.host;

  switch (host) {
    case 'trials-mobile.staging.intent.ag':
      config = STAGING_CONFIG;
      break;
    case 'trials-mobile.preview.intent.ag':
      config = PREVIEW_CONFIG;
      break;
    case 'trials-mobile.prod.intent.ag':
    case 'trials-mobile.intent.ag':
      config = PRODUCTION_CONFIG;
      break;
    default:
      config = DEV_CONFIG;
      break;
  }
}

export default config;
