import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: space-evenly;
  `,
  trialTitle: (theme: Theme) => css`
    padding: '0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}';
  `,
  helperContainer: (theme: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1)} 0;
  `,
  helperIcon: css`
    font-size: 14px;
    margin: 0 5px;
  `,
  helperText: css`
    font-size: 12px;
    font-weight: 500;
  `,
  mapContainer: css`
    height: calc(100dvh / 3);
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `
};
