import { useEffect, useMemo, useState } from 'react';

import { MyFieldVisit } from '@mytypes/protocol';

export interface MyVisitFilterState {
  filteredVisits: MyFieldVisit[];
  trialId: string | null;
  protocolId: string | null;
  growerId: string | null;
  expanded: boolean;
  growers: Option[];
  trials: Option[];
  protocols: Option[];
  setTrialId: (id: string | null) => void;
  setProtocolId: (id: string | null) => void;
  setGrowerId: (id: string | null) => void;
  setExpanded: (expanded: boolean) => void;
}

interface Option {
  id: string;
  value: string;
}

export default function useMyVisitFilterState({ visits }: { visits: MyFieldVisit[] | null | undefined }): MyVisitFilterState {
  const [trialId, setTrialId] = useState<string | null>(null);
  const [protocolId, setProtocolId] = useState<string | null>(null);
  const [growerId, setGrowerId] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const filteredVisits = useMemo(() => {
    if (!visits) return [];

    let filtered = [...visits];
    if (trialId) {
      filtered = filtered.filter(v => v.trial_id === trialId);
    }
    if (protocolId) {
      filtered = filtered.filter(v => v.protocol_id);
    }

    return filtered;
  }, [visits, trialId, protocolId]);

  const growers: Option[] = useMemo(() => {
    if (!visits) return [];

    let options: Option[] = [];

    visits.forEach(v => {
      if (!options.some(o => o.id === v.grower_id) && (!protocolId || v.protocol_id === protocolId) && (!trialId || v.trial_id === trialId)) {
        options.push({
          id: v.grower_id,
          value: v.grower_name
        });
      }
    });

    return options;
  }, [visits, trialId, protocolId]);

  const trials: Option[] = useMemo(() => {
    if (!visits) return [];

    let options: Option[] = [];

    visits.forEach(v => {
      if (!options.some(o => o.id === v.trial_id)) {
        options.push({
          id: v.trial_id,
          value: v.trial_name
        });
      }
    });

    return options;
  }, [visits]);

  const protocols: Option[] = useMemo(() => {
    if (!visits) return [];

    let options: Option[] = [];

    visits.forEach(v => {
      if (!options.some(o => o.id === v.protocol_id) && (!trialId || v.trial_id === trialId)) {
        options.push({
          id: v.protocol_id,
          value: v.protocol_name
        });
      }
    });

    return options;
  }, [visits, trialId]);

  useEffect(() => {
    if (protocolId && !protocols.some(o => o.id === protocolId)) {
      setProtocolId(null);
    }
  }, [protocols, protocolId]);

  useEffect(() => {
    if (growerId && !growers.some(o => o.id === growerId)) {
      setGrowerId(null);
    }
  }, [growers, growerId]);

  return {
    filteredVisits,
    trialId,
    protocolId,
    growerId,
    expanded,
    growers,
    trials,
    protocols,
    setTrialId,
    setProtocolId,
    setGrowerId,
    setExpanded
  };
}
