import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border-radius: 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  map: css`
    height: 500px;
    display: none;
  `,
  emptyStateMessage: (theme: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2)};
    flex-grow: 2;
    text-align: center;
  `,
  card: (theme: Theme) => css`
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    box-shadow: ${theme.shadows[1]};
  `,
  cardHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #35363a;
  `,
  cardHeaderTitle: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
    border-bottom: 1px solid #35363a;
  `,
  cardContent: (theme: Theme) => css`
    text-indent: ${theme.spacing(2)};
    padding-left: 0;
    padding-right: 0;
  `,
  cardActions: css`
    justify-content: flex-end;
  `
};
