import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border-radius: 0;
  `,
  stepper: css`
    margin-top: 2;
    margin-bottom: 2;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: ${theme.spacing(2)};
  `,
  divider: (theme: Theme) => css`
    margin: ${theme.spacing(2)} 0;
  `,
  chechEmail: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)} 0;
  `,
  chechEmailButton: (theme: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  loadingButton: css`
    width: 125px;
  `,
  enrollmentSuccessfulMessage: (theme: Theme) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  enrollmentSuccessfulMessageTitle: (theme: Theme) => css`
    font-size: 22px;
    padding: ${theme.spacing(1)} 0;
  `,

  actionsContainer: (theme: Theme) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)};
  `,
  actionBtn: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    width: 49%;
  `
};
