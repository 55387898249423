import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    height: calc(100dvh - 56px);
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  listLoadingOverlayContent: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  `,
  emptyStateMessage: (theme: Theme) => css`
    align-items: center;
    display: flex;
    flex-grow: 2;
    padding: ${theme.spacing(2)};
    text-align: center;
  `,
  visitCard: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(2)};
  `,
  visitCardHeader: css`
    align-items: center;
    border-bottom: 1px solid #35363a;
    display: flex;
    justify-content: space-between;
  `,
  visitCardActionsHeader: (theme: Theme) => css`
    display: flex;
    padding-right: ${theme.spacing(1)};
  `,
  visitCardActionsHeaderIcon: css`
    height: 34px;
  `,
  visitCardHeaderTitle: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
  `,
  visitCardContent: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
  `,
  visitCardActions: css`
    justify-content: flex-end;
  `
};
