import { useSelector } from 'react-redux';
import { RootState } from '@state-mgmt/store';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Grid, IconButton, ListSubheader, MenuItem, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { MyVisitFilterState } from './useMyVisitFilterState';
import { groupTrialsByYear, TrialsGropedByYear } from '@utils/groupTrialsByYear';
import { Trial } from '@mytypes/trial';

export default function MyVisitFilters({ state }: { state: MyVisitFilterState }) {
  const { t } = useTranslation();

  const { trials } = useSelector((state: RootState) => state.trial);

  const trialOptions = useMemo(() => {
    const options = [] as ReactNode[];
    const filtered = trials.filter(t => state.trials.some(o => o.id === t.id));
    const trialsGroupedByYear = groupTrialsByYear(filtered);

    const trialYears = Object.keys(trialsGroupedByYear)
      .sort((a: string, b: string) => a.localeCompare(b))
      .reverse();

    trialYears.forEach(year => {
      options.push(<ListSubheader key={year}>{year}</ListSubheader>);

      trialsGroupedByYear[year as keyof TrialsGropedByYear]
        .sort((a: Trial, b: Trial) => a.name.localeCompare(b.name))
        .forEach((trial: Trial) => {
          options.push(
            <MenuItem key={trial.id} value={trial.id}>
              {trial.name}
            </MenuItem>
          );
        });
    });
    return options;
  }, [trials, state.trials]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField select fullWidth label={t('general.trial')} value={state.trialId || ''} onChange={e => state.setTrialId(e.target.value || null)}>
          <MenuItem value="">{t('general.all')}</MenuItem>
          {trialOptions}
        </TextField>
      </Grid>

      {state.expanded ? (
        <Grid item xs={12}>
          <TextField select fullWidth label={t('general.protocol')} onChange={e => state.setProtocolId(e.target.value || null)} value={state.protocolId || ''}>
            <MenuItem value="">{t('general.all')}</MenuItem>
            {state.protocols.map(o => (
              <MenuItem key={o.id} value={o.id}>
                {o.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}

      {state.expanded ? (
        <Grid item xs={12}>
          <Autocomplete
            options={state.growers}
            renderInput={params => <TextField {...params} label={t('general.grower')} />}
            getOptionLabel={option => option.value}
            onChange={(_, newValue) => state.setGrowerId(newValue ? newValue.id : null)}
            isOptionEqualToValue={(o, value) => o.id === value.id}
            value={state.growerId ? state.growers.find(g => g.id === state.growerId) || null : null}
          />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container justifyContent={'center'}>
          <IconButton aria-label="expand" onClick={() => state.setExpanded(!state.expanded)}>
            {state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
