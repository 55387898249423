import { css } from '@emotion/react';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    border-radius: 0;
  `,
  loadingOverlayContent: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  `,
  content: css`
    row-gap: 24px;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    padding: 16px !important;
  `,
  inputget: css`
    font-size: 1px;
    position: absolute;
    z-index: -1;
    visibility: hidden;
  `,
  uploadBtn: css``,
  gallery: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    min-height: 100px;
    margin-bottom: 30px;
    padding: 10px;
  `,
  thumbnail: css`
    background-size: cover;
    position: relative;
    min-height: 170px;
  `,
  actionsContainer: css`
    display: flex;
    justify-content: center;
    padding: 16px !important;
  `
};
