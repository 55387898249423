import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    height: calc(100dvh - 56px);
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  filter: (theme: Theme) => css`
    margin: 6px 0;
  `,
  card: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
    box-shadow: ${theme.shadows[1]};
  `,
  cardTitle: (theme: Theme) => css`
    border-bottom: 1px solid #35363a;
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
  `,
  cardContent: (theme: Theme) => css`
    padding-left: 0;
    padding-right: 0;
    text-indent: ${theme.spacing(2)};
  `,
  cardActions: css`
    justify-content: flex-end;
  `,
  listLoadingOverlayContent: css`
    height: 100%;
    position: relative;
  `,
  emptyStateMessage: (theme: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2)};
    flex-grow: 2;
    text-align: center;
  `
};
