import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border-radius: 0;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  card: (theme: Theme) => css`
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  cardTitle: (theme: Theme) => css`
    padding: ${theme.spacing(1)} 0;
    text-indent: ${theme.spacing(2)};
    border-bottom: 1px solid #35363a;
  `,
  cardContent: (theme: Theme) => css`
    text-indent: ${theme.spacing(2)};
    padding-left: 0;
    padding-right: 0;
  `,
  cardContentText: css`
    font-weight: 100;
  `,
  cardActions: css`
    justify-content: flex-end;
  `
};
