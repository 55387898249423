import DeviceDetector from 'device-detector-js';

interface ExtendedNavigator extends Navigator {
  connection?: {
    type?: string;
    effectiveType?: string;
    rtt?: number;
  };
  mozConnection?: {
    type?: string;
    effectiveType?: string;
    rtt?: number;
  };
  webkitConnection?: {
    type?: string;
    effectiveType?: string;
    rtt?: number;
  };
  getBattery?: () => Promise<{ level: number; charging: boolean }>;
}

export const getUserDeviceInfo = async () => {
  const navigatorExt = navigator as ExtendedNavigator;
  const deviceDetector = new DeviceDetector();
  const deviceInfo = deviceDetector.parse(navigator.userAgent);

  const info = {
    browser_name: deviceInfo.client?.name || 'Unknown',
    browser_version: deviceInfo.client?.version || 'Unknown',
    os_name: deviceInfo.os?.name || 'Unknown',
    os_version: deviceInfo.os?.version || 'Unknown',
    device_type: deviceInfo.device?.type || 'Unknown',
    device_brand: deviceInfo.device?.brand || 'Unknown',
    device_model: deviceInfo.device?.model || 'Unknown',
    network_type: 'Unknown',
    effective_connection_type: 'Unknown',
    round_trip_time: 'Unknown'
  };

  const connection = navigatorExt.connection || navigatorExt.mozConnection || navigatorExt.webkitConnection;
  if (connection) {
    info.network_type = connection.type || 'Unknown';
    info.effective_connection_type = connection.effectiveType || 'Unknown';
    info.round_trip_time = connection.rtt ? `${connection.rtt}ms` : 'Unknown';
  }

  return info;
};
