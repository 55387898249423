import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Protocol } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';
import { getProtocolMyFieldVisitsCount } from '@services/protocol';

export type ProtocolState = {
  protocols: Protocol[];
  currentProtocol: string | undefined;
  myVisitsCount: number;
};

const initialState: ProtocolState = {
  protocols: [],
  currentProtocol: undefined,
  myVisitsCount: 0
};

export const fetchMyVisitsCount = createAsyncThunk('protocol/getMyVisitsCount', async (input: { clientId: string }) => {
  const response = await getProtocolMyFieldVisitsCount({ clientId: input.clientId });

  if (response && (response?.count || response?.count === 0)) {
    return response.count;
  } else {
    throw new Error('Invalid data returned from protocol get my visits count');
  }
});

export const protocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    setProtocols(state: ProtocolState, action: PayloadAction<Protocol[]>) {
      state.protocols = action.payload;
    },
    setCurrentProtocol(state: ProtocolState, action: PayloadAction<string | undefined>) {
      state.currentProtocol = action.payload;
    },
    setMyVisitsCount(state: ProtocolState, action: PayloadAction<number>) {
      state.myVisitsCount = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMyVisitsCount.fulfilled, (state, action) => {
        state.myVisitsCount = action.payload;
      })
      .addCase(fetchMyVisitsCount.rejected, state => {
        state.myVisitsCount = 0;
      });
  }
});

// Actions
export const { setProtocols, setCurrentProtocol, setMyVisitsCount } = protocolSlice.actions;

// Selectors
export const selectProtocols = (state: RootState) => state.protocol.protocols;
export const selectCurrentProtocol = (state: RootState) => state.protocol.currentProtocol;
export const selectMyVisitsCount = (state: RootState) => state.protocol.myVisitsCount;

export default protocolSlice.reducer;
