import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => css`
    // 56 = bottom navigator height
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    border-radius: 0;
  `,
  emptyContainer: (theme: Theme) => css`
    height: 80%;
    padding: 0 ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
  `,
  scrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 ${theme.spacing(2)};
  `,
  listLoadingOverlayContent: css`
    position: relative;
    height: 100%;
  `,
  notesCard: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(2)};
  `,
  notesCardHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #35363a;
  `,
  notesCardActionsHeader: (theme: Theme) => css`
    display: flex;
    padding-right: ${theme.spacing(1)};
  `,
  reportIcon: css`
    height: 34px;
  `,
  title: () => css`
    font-size: 22px;
  `,
  subTitle: () => css`
    font-size: 18px;
    text-align: center;
  `,
  notesCardHeaderTitle: (theme: Theme) => css`
    white-space: nowrap;
    overflow: hidden;
    align-items: baseline;
    padding: ${theme.spacing(2)};
  `,
  notesCardContent: (theme: Theme) => css`
    padding: ${theme.spacing(2)};
  `,
  notesCardActions: css`
    justify-content: flex-end;
  `,
  createNoteBtnContainer: css`
    display: flex;
    justify-content: center;
  `,
  createNoteBtn: css`
    width: 200px;
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  mapContainer: css`
    min-height: calc(100dvh / 3);
    display: flex;
    flex-direction: column;
  `,
  mapLoadingOverlayWrapper: css`
    position: relative;
    height: 100%;
  `,
  viewNoteScrollableArea: (theme: Theme) => css`
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: ${theme.spacing(2)};
  `,
  infoRow: css`
    margin-bottom: 10px;
    margin-top: 10px;
  `,
  photos: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  `,
  thumbnail: css`
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 150px;
  `
};
