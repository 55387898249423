import { FeatureCollection } from '@turf/turf';

import { getBoundariesApi, exportTableApi } from '@services/mdi';
import { getSoilTableApi } from '@services/soild';
import { getFieldUploadsApi } from '@services/protocol';

const b64ToUtf8 = (str: string) => {
  return decodeURIComponent(escape(window.atob(str)));
};

const decodeTable = (base64String: string | undefined) => {
  if (base64String) {
    const dataDecoded = b64ToUtf8(base64String);
    return JSON.parse(dataDecoded);
  }
  return undefined;
};

export const getFieldTables = async (
  trialId: string,
  protocolId: string,
  fieldId: string,
  includeTreatmentsZones: boolean = false,
  includeSoil: boolean = false
): Promise<any | undefined> => {
  const [treatmentsZones, { field, area, soil }] = await Promise.all([
    // zones
    (async () => {
      let treatmentsZones = undefined;
      if (includeTreatmentsZones) {
        const fieldUploads = await getFieldUploadsApi({ trialId, protocolId, fieldId });
        if (fieldUploads) {
          const zoneUpload = fieldUploads.uploads.find(upload => upload.file_type === 'ZONE' && !upload.archived);
          if (zoneUpload) {
            const treatmentZoneDataBase64 = await exportTableApi({ tableGuid: zoneUpload.table_guid });
            treatmentsZones = decodeTable(treatmentZoneDataBase64);
          }
        }
      }
      return treatmentsZones;
    })().catch(err => {
      console.error('An error occurred fetching trial zones', err);
      throw new Error('Could now fetch treatment zones');
    }),
    // field boundary, trial area, ssurgo
    (async () => {
      let field;
      const fieldData = await getBoundariesApi({ fieldId, protocolId });

      const boundary = fieldData?.find(item => item.operation === 'boundary');
      const subboundary = fieldData?.find(item => item.operation === 'subboundary');

      field = decodeTable(boundary?.geometry);
      const fieldTableGuid = boundary?.table_guid;

      const area = decodeTable(subboundary?.geometry);
      let soil = undefined;
      if (includeSoil && fieldTableGuid) {
        try {
          const soilFieldDataBase64 = await getSoilTableApi({ tableGuid: fieldTableGuid });
          soil = decodeTable(soilFieldDataBase64) as FeatureCollection;
        } catch (err) {
          console.error('Could not fetch soils', err);
        }
      }
      return { field, area, soil };
    })().catch(error => {
      console.error('An error occurred fetching field tables boundaries', error);
      throw new Error('Could not fetch boundaries');
    })
  ]);

  return {
    field,
    area,
    treatmentsZones,
    soil
  };
};
