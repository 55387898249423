export type Protocol = {
  crop_id: string;
  id: string;
  name: string;
  steps: {
    description: string;
    end_date: string;
    label: string;
    manual: boolean;
    mobile: boolean;
    required: boolean;
    start_date: string;
    step_id: string;
    type: string;
  }[];
};

export type Step = {
  admin_characterizations: AdminCharacterization[];
  description: string;
  end_date: string;
  field_characterizations: StepFieldCharacterization;
  fields_completed: number;
  fields_in_progress: number;
  label: string;
  mobile: boolean;
  pages: ProtocolPage[];
  required: boolean;
  start_date?: string;
  step_id: string;
  step_type: string;
};

export type ProtocolPage = {
  description: string;
  label: string;
  number: number;
  page_id: string;
  product_ids?: string[] | null | undefined;
};

export type Page = {
  label: string;
  order: number;
  pageId: string;
  products: string[];
  questions: Question[];
};

export enum QUESTION_TYPE {
  DATE = 'date',
  DECIMAL = 'decimal',
  FLAG = 'flag',
  INTEGER = 'integer',
  MULTI_SELECTION = 'multi-selection',
  SELECTION = 'selection',
  TEXT = 'text',
  UPLOAD = 'upload'
}

export type Question = {
  characterization_id: string;
  label: string;
  latitude?: number;
  longitude?: number;
  options?: string[];
  order: number;
  required: boolean;
  value?: any;
  valueType: QUESTION_TYPE;
  pageId?: string;
  valueId?: any[];
};

export type FieldVisitStep = {
  localId: number | null;
  stepId: string;
  label: string;
  completedBy: string;
  completedAt: string;
  mainActionCompleted: string;
  startDate: string;
  endDate: string;
  pages: Page[];
  savedValues?: any[];
};

export type Characteristic = {
  characterization_id: string;
  completed: boolean;
  hide: boolean;
  label: string;
  options: string[];
  order: number;
  page_id?: string;
  required: boolean;
  value_type: QUESTION_TYPE;
};

export type AdminCharacterization = {
  characterization_id: string;
  label: string;
  options: string[];
  value_type: string;
};

export type StepFieldCharacterization = {
  matrix: any[];
  rank: any[];
  standard: Characteristic[];
};

export enum STEP_TYPE {
  APPLICATION = 'application',
  FIELD_VISIT = 'field-visit',
  PLANTING = 'planting'
}

export type ProtocolStatus = {
  crop_id: string;
  fields_enrolled: number;
  growers_enrolled: number;
  name: string;
  status: Step[];
};

export enum STEP_STATUS {
  PENDING = 'pending',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete'
}

export type Note = {
  created_by: string;
  created_by_firstt: string;
  created_by_lastt: string;
  date: string;
  id: string;
  media: {
    bucket_location: string;
    media_type: string;
    upload_id: string;
  }[];
  note: string;
  updated_at: string;
  wkt_point: string;
  zone_id: string | null;
};

export type Crop = {
  id: string;
  name: string;
};

// request and response api payloads

export type GetProtocolsResponsePayload = {
  protocols: {
    crop_id: string;
    id: string;
    name: string;
    steps: {
      description: string;
      end_date: string;
      label: string;
      manual: boolean;
      mobile: boolean;
      required: boolean;
      start_date: string;
      step_id: string;
      type: string;
    }[];
  }[];
};

export type GetProtocolStatusRequestPayload = {
  trialId: string;
  protocolId: string;
  stepType: string;
};

export type GetProtocolStatusResponsePayload = {
  crop_id: string;
  fields_enrolled: number;
  growers_enrolled: number;
  name: string;
  status: Step[];
};

export type GetStepCharacterizationsRequestPayload = {
  trialId: string;
  protocolId: string;
  stepId: string;
};

export type GetStepCharacterizationsResponsePayload = {
  admin_characterizations: AdminCharacterization[];
  description: string;
  field_characterizations: {
    matrix: any[];
    rank: any[];
    standard: Characteristic[];
  };
  label: string;
  step_id: string;
  step_type: string;
};

export type ProtocolStatusField = {
  analytics_approved: boolean;
  analytics_approved_at: boolean | null;
  analytics_comments: boolean | null;
  analytics_included: boolean;
  assignee_id: boolean | null;
  characterizations_completed: string;
  completed_by_first: string;
  completed_by_id: string;
  completed_by_last: string;
  completed_date: string;
  description: string;
  end_date: string;
  label: string;
  main_action_completed: string;
  manual: boolean;
  mobile: boolean;
  required: boolean;
  start_date: string;
  step_id: string;
  step_type: string;
};

export type GetProtocolStatusFieldResponsePayload = {
  status: ProtocolStatusField[];
};

export type MyFieldVisit = {
  trial_id: string;
  trial_name: string;
  protocol_id: string;
  protocol_name: string;
  field_id: string;
  field_name: string;
  grower_id: string;
  grower_name: string;
  main_action_completed: string;
  label: string;
  completed_at: string | null | undefined;
  completed_by: string | null | undefined;
  step_id: string;
};

export type GetProtocolMyFieldVisitsResponsePayload = {
  visits: MyFieldVisit[];
  complete_visits: MyFieldVisit[];
};

export type GetProtocolMyFieldVisitsCountResponsePayload = {
  count: number;
};

export type GetProtocolStatusFieldRequestPayload = {
  trialId: string;
  protocolId: string;
  fieldId: string;
};
export type GetProtocolMyFieldVisitsRequestPayload = {
  clientId: string;
};
export type GetProtocolMyFieldVisitsCountRequestPayload = {
  clientId: string;
};

export type GetFieldVisitValuesRequestPayload = {
  trialId: string;
  protocolId: string;
  fieldId: string;
};

export type GetFieldVisitValuesResponsePayload = {
  values: {
    characterization_id: string;
    characterization_label: string;
    field_id: string;
    hide: false;
    id: string;
    step_id: string;
    value: string;
    wkt_point: string;
    zone_id: string;
  }[];
};

export type SubmitCharacteristicsValuesRequestPayload = {
  characterizations: {
    characterization_id: string;
    values: (string | number | boolean)[];
    latitude: number | undefined;
    longitude: number | undefined;
  }[];
  complete: boolean;
  fieldId: string;
  protocolId: string;
  stepId: string;
  trialId: string;
};

export type SubmitCharacteristicsValuesResponsePayload = {
  result: string;
};

export type DeleteCharacteristicValueApiRequestPayload = {
  characterizationId: string;
  fieldId: string;
  protocolId: string;
  stepId: string;
  trialId: string;
  valueId: string;
};

export type DeleteCharacteristicValueApiResponsePayload = {
  result: string;
};

export type SubmitsCharacteristicUploadRequestPayload = {
  characterizationId: string;
  fieldId: string;
  latitude: string | undefined;
  longitude: string | undefined;
  protocolId: string;
  remove: any;
  stepId: string;
  trialId: string;
  value: string;
  valueId: string;
};

export type SubmitsCharacteristicUploadResponsePayload = {
  result: string;
  url?: string | null;
  id?: string | null;
};

export type CompleteStepRequestPayload = {
  trialId: string;
  stepId: string;
  protocolId: string;
  fieldId: string;
  clientId: string;
};

export type CompleteStepResponsetPayload = {
  result: string;
};

export type GetFieldUploadsRequestPayload = {
  trialId: string;
  protocolId: string;
  fieldId: string;
};

export type GetFieldUploadsResponsePayload = {
  uploads: {
    archived: boolean;
    comments: null | string;
    converted_bucket_location: null | string;
    field_id: string;
    file_type: string;
    grower_id: string;
    grower_name: string;
    processed: boolean;
    processed_bucket_location: null | string;
    processor_id: null | string;
    processor_name: string;
    protocol_id: null | string;
    raw_bucket_location: null | string;
    source: string;
    step_id: string;
    table_guid: string;
    upload_date: string;
    upload_id: string;
    upload_status: string;
    uploader_id: string;
    uploader_name: string;
  }[];
};

export type EnrollFieldRequestPayload = {
  clientId: string;
  fieldId: string;
  protocolId: string;
  trialId: string;
};

export type EnrollFieldResponsePayload = {
  result: string;
};

export type DropoutFieldRequestPayload = {
  fieldId: string;
  protocolId: string;
  trialId: string;
};

export type DropoutFieldResponsePayload = {
  result: string;
};

export type UnDropoutFieldRequestPayload = {
  fieldId: string;
  protocolId: string;
};

export type UnDropoutFieldResponsePayload = {
  result: string;
};

export type DropoutGrowerRequestPayload = {
  protocolId: string;
  trialId: string;
  userId: string;
};

export type DropoutGrowerResponsePayload = {
  result: string;
};

export type GetFieldNotesRequestPayload = {
  fieldId: string;
  protocolId: string;
  trialId: string;
};

export type GetFieldNotesResponsePayload = {
  notes: Note[];
};

export type UpdateFieldNoteRequestPayload = {
  date: string;
  fieldId: string;
  field_note_id: string;
  latitude?: number;
  longitude?: number;
  note: string;
  protocolId: string;
  trialId: string;
  zone_id?: string | null | undefined;
};

export type UpdateFieldNoteResponsePayload = {
  result: string;
};

export type SubmitFieldNotesUploadRequestPayload = {
  fieldId: string;
  field_note_id: string;
  file: File;
  latitude?: number;
  longitude?: number;
  protocolId: string;
  trialId: string;
  upload_type: string;
};

export type SubmitFieldNotesUploadResponsePayload = {
  result: string;
};

export type DeleteFieldNotesUploadRequestPayload = {
  fieldId: string;
  noteId: string;
  protocolId: string;
  trialId: string;
  uploadId: string;
};

export type DeleteFieldNotesUploadResponsePayload = {
  result: string;
};

export type CreateFieldNoteRequestPayload = {
  date: string;
  fieldId: string;
  latitude?: number | null;
  longitude?: number | null;
  note: string;
  noteId: string;
  protocolId: string;
  trialId: string;
  zone_id?: string | null;
};

export type CreateFieldNoteResponsePayload = {
  result: string;
};

export type GetProtocolCropsListResponsePayload = {
  crops: Crop[];
};
