import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getProtocolMyFieldVisits } from '@services/protocol';
import { AppDispatch } from '@state-mgmt/store';
import { useDispatch } from 'react-redux';
import { setMyVisitsCount } from '@state-mgmt/slices/protocol-slice';

export const useGetMyFieldVisits = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useAsyncCallback(
    useCallback(
      async (clientId: string) => {
        try {
          const response = await getProtocolMyFieldVisits({ clientId });

          // Keep my visits badge count up to date
          if (response && Array.isArray(response?.visits)) {
            dispatch(setMyVisitsCount(response.visits.length));
          }

          return response;
        } catch (error) {
          console.error('an error occurred fetching field visit steps', error);
          throw new Error('Could not getFieldVisitSteps');
        }
      },
      [dispatch]
    )
  );
};
