/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, List, Typography } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface fieldCard {
  clientId: string;
  clientName: string;
  fieldId: string;
  fieldName: string;
  growerId: string;
  growerName: string;
  protocolId: string;
  protocolName: string;
  trialId: string;
  trialName: string;
  mySteps?: { stepId: string; label: string }[] | null;
}

export interface Props {
  fields: fieldCard[];
  onAddField: (field: fieldCard) => void;
  onRemoveField: (field: fieldCard) => void;
}

const FieldsList = ({ fields, onAddField, onRemoveField }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { fields: selectedFields } = useSelector((state: RootState) => state.downloader);

  const isFieldSelected = useCallback(
    (trialId: string, protocolId: string, fieldId: string) =>
      selectedFields.some(field => field.trialId === trialId && field.protocolId === protocolId && field.fieldId === fieldId),
    [selectedFields]
  );

  return (
    <List>
      {fields.map((field, index) => (
        <Card key={index} elevation={0} css={styles.card(theme)}>
          <Typography color="text.primary" css={styles.cardHeader(theme)}>
            {field.fieldName}
          </Typography>

          <CardContent css={styles.cardContent(theme)}>
            {field.mySteps ? (
              <Typography color="text.primary" fontSize={14}>
                {t('general.trial')}: {field.trialName}
              </Typography>
            ) : null}
            <Typography color="text.primary" fontSize={14}>
              {t('general.protocol')}: {field.protocolName}
            </Typography>
            <Typography color="text.primary" fontSize={14} marginBottom={1}>
              {t('general.grower')}: {field.growerName}
            </Typography>

            {field.mySteps
              ? field.mySteps.map(s => {
                  return (
                    <Typography color="text.primary" variant="subtitle2" fontSize={14} key={s.stepId}>
                      {s.label}
                    </Typography>
                  );
                })
              : null}
          </CardContent>

          <CardActions css={styles.cardActions}>
            {isFieldSelected(field.trialId, field.protocolId, field.fieldId) ? (
              <Button variant="contained" size="small" color="error" onClick={() => onRemoveField(field)}>
                {t('general.remove')}
              </Button>
            ) : (
              <Button variant="contained" size="small" onClick={() => onAddField(field)}>
                {t('general.add')}
              </Button>
            )}
          </CardActions>
        </Card>
      ))}
    </List>
  );
};

export default FieldsList;
