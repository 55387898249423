import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { SignIn } from '@components/SignIn';
import { NoConnectionIndicator } from '@components/NoConnectionIndicator';
import ProtectedRoute from '@components/ProtectedRoute/ProtectedRoute';
import { Splash } from '@components/pages/Splash';
import { Home } from '@components/pages/Home';
import { GrowerDetail } from '@components/pages/GrowerDetail';
import { UserProfile } from '@components/pages/UserProfile';
import { FieldMap } from '@components/pages/FieldMap';
import { VerifyEmail } from '@components/VerifyEmail';
import { FieldVisitWizard } from '@components/pages/FieldVisitWizard';
import { FieldVisits } from '@components/pages/FieldVisits';
import { Enrollment } from '@components/pages/Enrollment';
import { EnrollmentWizard } from '@components/pages/EnrollmentWizard';
import { SyncFieldVisits } from '@components/pages/SyncFieldVisits';
import { UpdateBanner } from '@components/UpdateBanner';
import { Updating } from '@components/pages/Updating';
import { TrialFieldsMap } from '@components/pages/TrialFieldsMap';
import ViewNote from '@components/pages/FieldNotes/ViewNote';
import FieldNotes from '@components/pages/FieldNotes';
import FieldNoteEdit from '@components/pages/FieldNoteEdit';
import FieldNoteCreate from '@components/pages/FieldNoteEdit/FieldNoteCreate';
import { ToastList } from '@components/ui/ToastList';
import { ModalList } from '@components/ui/ModalList';
import { Planner } from '@components/Planner/Planner';
import { PlanFieldVisits } from '@components/Planner/PlanFieldVisits';
import { NewPlanWizard } from '@components/Planner/NewPlanWizard';
import { DownloadFieldVisitPlan } from '@components/Planner/DownloadFieldVisitPlan';
import { OperativeDetail } from '@components/pages/OperativeDetail';
import { CreateHelpDeskTicket } from '@components/pages/CreateHelpDeskTicket';
import { ErrorFallback } from '@components/ErrorFallback';
import { useMustSyncFieldVisits } from '@hooks/useMustSyncFieldVisits';
import { useAppTheme } from '@hooks/useAppTheme';
import { useSyncVisitsModal } from '@hooks/modals/useSyncVisitsModal';
import { useFieldVisitWIPModal } from '@hooks/modals/useFieldVisitWIPModal';
import { getFieldVisitProgressWithDexie } from '@services/local';
import { AppDispatch, RootState } from '@state-mgmt/store';
import { setSyncEnabled } from '@state-mgmt/slices/appSlice';

import { styles } from '../../styles';
import MyVisits from '@components/pages/MyVisits';
import { fetchMyVisitsCount } from '@state-mgmt/slices/protocol-slice';

const AppRouter = () => {
  const { online, isSplashing, themeMode, clientId } = useSelector((state: RootState) => state.app);
  const theme = useAppTheme({ mode: themeMode });

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [mustSyncFieldVisitsState, mustSyncFieldVisits] = useMustSyncFieldVisits();

  const { displayModal: displaySyncVisitsModal } = useSyncVisitsModal();
  const { displayModal: displayFieldVisitWIPModal } = useFieldVisitWIPModal();

  useEffect(() => {
    if (online) {
      mustSyncFieldVisits();
    }
  }, [online, isSplashing]);

  useEffect(() => {
    if (online && clientId) {
      dispatch(fetchMyVisitsCount({ clientId }));
    }
  }, [dispatch, online, clientId]);

  useEffect(() => {
    const syncEnabled = online && !isSplashing && !!mustSyncFieldVisitsState.data;
    syncEnabled && displaySyncVisitsModal();
    dispatch(setSyncEnabled(syncEnabled));
  }, [mustSyncFieldVisitsState.data, online, isSplashing]);

  useEffect(() => {
    if (
      isSplashing !== undefined &&
      !isSplashing &&
      (!location.pathname.includes('/field-visits') || !location.pathname.includes('/start')) &&
      !location.pathname.includes('/splash')
    ) {
      getFieldVisitProgressWithDexie().then(visitProgress => {
        if (visitProgress) {
          displayFieldVisitWIPModal(visitProgress);
        }
      });
    }
  }, [online, location, isSplashing]);

  const logError = (error: Error, info: { componentStack: string }) => {
    console.error(error);
    Sentry.captureException(error, { extra: info });
  };

  return (
    <ThemeProvider theme={theme}>
      {!online && <NoConnectionIndicator />}

      <CssBaseline />
      <ToastContainer />
      <Global styles={styles(themeMode)} />

      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <ToastList />
        <ModalList />
        <UpdateBanner />

        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/password/reset" element={<VerifyEmail />} />
          <>
            <Route path="/" element={<ProtectedRoute children={<Home />} />} />
            <Route path="/splash" element={<ProtectedRoute children={<Splash />} />} />
            <Route path="/sync-field-visits" element={<ProtectedRoute children={<SyncFieldVisits />} />} />
            <Route path="/updating" element={<ProtectedRoute children={<Updating />} />} />
            <Route path="/grower/:memberId" element={<ProtectedRoute children={<GrowerDetail />} />} />
            <Route path="/operative/:memberId" element={<ProtectedRoute children={<OperativeDetail />} />} />
            <Route path="/planner" element={<ProtectedRoute children={<Planner />} />} />
            <Route path="/planner/new/:planId?" element={<ProtectedRoute children={<NewPlanWizard />} />} />
            <Route path="/planner/:planId" element={<ProtectedRoute children={<PlanFieldVisits />} />} />
            <Route path="/planner/:planId/download" element={<ProtectedRoute children={<DownloadFieldVisitPlan />} />} />
            <Route path="/my-visits" element={<ProtectedRoute children={<MyVisits />} />} />
            <Route path="/enrollment" element={<ProtectedRoute children={<Enrollment />} />} />
            <Route path="/enrollment/new" element={<ProtectedRoute children={<EnrollmentWizard />} />} />
            <Route path="/profile" element={<ProtectedRoute children={<UserProfile />} />} />
            <Route path="/support" element={<ProtectedRoute children={<CreateHelpDeskTicket />} />} />
            <Route path="/field-map/:fieldId/:protocolId" element={<ProtectedRoute children={<FieldMap />} />} />
            <Route path="/field-visits/:fieldId/:protocolId/:downloadId?" element={<ProtectedRoute children={<FieldVisits />} />} />
            <Route path="/field-visits/:fieldId/:protocolId/start/:stepId/:downloadId?" element={<ProtectedRoute children={<FieldVisitWizard />} />} />
            <Route path="/field-notes/:fieldId/:protocolId/:growerId" element={<ProtectedRoute children={<FieldNotes />} />} />
            <Route path="/field-notes/:fieldId/:protocolId/:growerId/view/:noteId" element={<ProtectedRoute children={<ViewNote />} />} />
            <Route path="/field-notes/:fieldId/:protocolId/:growerId/edit/:noteId" element={<ProtectedRoute children={<FieldNoteEdit />} />} />
            <Route path="/field-notes/:fieldId/:protocolId/:growerId/create" element={<ProtectedRoute children={<FieldNoteCreate />} />} />
            <Route path="/trial-fields-map" element={<ProtectedRoute children={<TrialFieldsMap />} />} />
          </>
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default AppRouter;
