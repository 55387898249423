import { css } from '@emotion/react';
import { THEME_MODE } from '@state-mgmt/slices/appSlice';
import { COLORS } from '../../styles';

export const styles = {
  container: css`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
  `,
  navigator: (themeMode: THEME_MODE) => css`
    background-color: ${themeMode === THEME_MODE.DARK ? COLORS.dark.navigator.background : COLORS.light.navigator.background};
  `,
  hidden: css`
    display: none;
  `,
  syncBtn: (themeMode: THEME_MODE) => css`
    color: ${themeMode === THEME_MODE.DARK ? COLORS.dark.navigator.button.textDiabled : COLORS.light.navigator.button.textDiabled};
  `,
  syncBtnEnabled: (themeMode: THEME_MODE) => css`
    color: ${themeMode === THEME_MODE.DARK ? COLORS.dark.navigator.syncEnabled : COLORS.light.navigator.syncEnabled};
  `,
  syncBtnIcon: (themeMode: THEME_MODE) => css`
    color: ${themeMode === THEME_MODE.DARK ? COLORS.dark.navigator.button.textDiabled : COLORS.light.navigator.button.textDiabled};
  `,
  syncBtnIconEnabled: (themeMode: THEME_MODE) => css`
    color: ${themeMode === THEME_MODE.DARK ? COLORS.dark.navigator.syncEnabled : COLORS.light.navigator.syncEnabled};
  `
};
