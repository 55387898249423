/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { setShowAppNavigator } from '@state-mgmt/slices/appSlice';
import { RootState } from '@state-mgmt/store';
import { clearAppData } from '@utils/clearAppData';
import { styles } from './styles';
import config from '../../config';

const UpdateBanner = () => {
  const { t } = useTranslation();
  const [showUpdate, setShowUpdate] = useState(false);
  const location = useLocation();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { online, isSplashing } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (online && ['/', '/profile'].includes(location.pathname)) {
      fetchManifest().then(appLatesttAppVersion => {
        if (config.REACT_APP_APP_VERSION !== appLatesttAppVersion) {
          setShowUpdate(true);
        }
      });
    }
  }, [online, location]);

  const fetchManifest = async () => {
    try {
      const response = await fetch('/manifest.json');
      const manifestData = await response.json();
      return manifestData.version;
    } catch (error) {
      console.error('Error fetching manifest:', error);
    }
  };

  const updateApp = async () => {
    setShowUpdate(false);
    dispatch(setShowAppNavigator(false));
    await clearAppData();
    navigate('/updating');
  };

  return showUpdate && !isSplashing ? (
    <div css={styles.container(theme)}>
      <p css={styles.text}>{t('update-banner.title')}</p>
      <Button variant="outlined" color="warning" onClick={() => updateApp()}>
        {t('update-banner.update-button')}
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default UpdateBanner;
