/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import Dayjs from 'dayjs';

import { Button, Card, CardActions, CardContent, IconButton, List, Paper, Tab, Tabs, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import EditIcon from '@mui/icons-material/Edit';

import { ViewHeader } from '@components/ViewHeader';
import { ToastType } from '@components/ui/Toast';

import { useAppTheme } from '@hooks/useAppTheme';

import { useSubmitsFieldDataAsync } from '@hooks/useSubmitsFieldDataAsync';

import { useToast } from '@hooks/app/useToast';
import { MyFieldVisit, STEP_STATUS } from '@mytypes/protocol';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';
import { useGetMyFieldVisits } from '@hooks/useGetMyFieldVisits';
import { setCurrentProtocol } from '@state-mgmt/slices/protocol-slice';
import { setTrialId } from '@state-mgmt/slices/appSlice';
import useMyVisitFilterState from '@components/MyVisitFilters/useMyVisitFilterState';
import MyVisitFilters from '@components/MyVisitFilters/MyVisitFilters';

const FieldVisits = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const showToast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { online, clientId, trialId } = useSelector((state: RootState) => state.app);
  const [tab, setTab] = useState<number>(0);

  const [submitsFieldDataState] = useSubmitsFieldDataAsync();

  const [getMyVisitsState, getMyFieldVisits] = useGetMyFieldVisits();

  const incompleteData = getMyVisitsState.data?.visits || [];
  const completeData = getMyVisitsState.data?.complete_visits || [];

  const myVisitFilterState = useMyVisitFilterState({ visits: incompleteData });
  const data = tab === 1 ? completeData : myVisitFilterState.filteredVisits;

  useEffect(() => {
    if (clientId) {
      getMyFieldVisits(clientId);
    }
  }, [clientId, getMyFieldVisits]);

  useEffect(() => {
    if (clientId && submitsFieldDataState.isSuccess) {
      getMyFieldVisits(clientId);
    }
  }, [clientId, submitsFieldDataState.isSuccess, getMyFieldVisits]);

  useEffect(() => {
    if (submitsFieldDataState.error) {
      showToast({ type: ToastType.ERROR, children: t('my-visits.error-message') });
    }
  }, [submitsFieldDataState.error, showToast, t]);

  const onStartBtnPress = (visit: MyFieldVisit) => {
    let url = `/field-visits/${visit.field_id}/${visit.protocol_id}/start/${visit.step_id}?grower_id=${visit.grower_id}&from=my-visits`;

    if (trialId !== visit.trial_id) {
      dispatch(setTrialId(visit.trial_id));
      localStorage.setItem('trialId', visit.trial_id);
      dispatch(setCurrentProtocol(undefined));
    }

    navigate(url);
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('my-visits.title')} onGoBackBtnPress={undefined} />
      <div css={styles.scrollableArea(theme)}>
        <LoadingOverlay
          spinner
          active={getMyVisitsState.isLoading}
          text={t('my-visits.loading-message')}
          styles={{ wrapper: styles.listLoadingOverlayContent }}
        >
          <Tabs sx={{ margin: '8px auto' }} value={tab} onChange={(_, value) => setTab(value)} aria-label="My Visits Tabs">
            <Tab label={t('my-visits.incomplete-tab')} />
            <Tab label={t('my-visits.complete-tab')} />
          </Tabs>
          {!getMyVisitsState.isLoading && (!data || data?.length === 0) ? (
            <Typography color="text.secondary" css={styles.emptyStateMessage(theme)}>
              {t('my-visits.empty-state-text')}
            </Typography>
          ) : (
            <>
              {tab === 0 ? <MyVisitFilters state={myVisitFilterState} /> : null}
              <List>
                {data?.map(step => (
                  <Card key={step.field_id + ':' + step.step_id} elevation={0} css={styles.visitCard(theme)}>
                    <div css={styles.visitCardHeader}>
                      <Typography color="text.primary" css={styles.visitCardHeaderTitle(theme)}>
                        {step.label}
                      </Typography>
                      <div css={styles.visitCardActionsHeader(theme)}>
                        {step.main_action_completed === STEP_STATUS.COMPLETE ? (
                          <IconButton size="small" onClick={() => onStartBtnPress(step)} disabled={!online}>
                            <EditIcon />
                          </IconButton>
                        ) : null}
                        {step.main_action_completed === STEP_STATUS.COMPLETE ? (
                          <CheckCircleOutlineIcon color={'success'} css={styles.visitCardActionsHeaderIcon} />
                        ) : null}
                        {[STEP_STATUS.INCOMPLETE, STEP_STATUS.PENDING].includes(step.main_action_completed as STEP_STATUS) ? (
                          <ReportGmailerrorredIcon color="warning" css={styles.visitCardActionsHeaderIcon} />
                        ) : null}
                      </div>
                    </div>

                    <CardContent css={styles.visitCardContent(theme)}>
                      <>
                        <Typography color="text.primary" fontSize={14}>
                          {t('my-visits.trial-name-label')}: {step.trial_name}
                        </Typography>
                        <Typography color="text.primary" fontSize={14}>
                          {t('my-visits.protocol-name-label')}: {step.protocol_name}
                        </Typography>
                        <Typography color="text.primary" fontSize={14}>
                          {t('my-visits.grower-name-label')}: {step.grower_name}
                        </Typography>
                        <Typography color="text.primary" fontSize={14}>
                          {t('my-visits.field-name-label')}: {step.field_name}
                        </Typography>
                        {step.main_action_completed === STEP_STATUS.COMPLETE ? (
                          <>
                            <Typography color="text.primary" fontSize={14}>
                              {t('my-visits.completed-by-label')}: {step.completed_by}
                            </Typography>

                            <Typography color="text.primary" fontSize={14}>
                              {t('my-visits.completed-date-label')}: {Dayjs(step.completed_at).format('MM/DD/YYYY')}
                            </Typography>
                          </>
                        ) : null}
                      </>
                    </CardContent>

                    <CardActions css={styles.visitCardActions}>
                      {[STEP_STATUS.INCOMPLETE, STEP_STATUS.PENDING].includes(step.main_action_completed as STEP_STATUS) ? (
                        <Button size="small" variant="contained" onClick={() => onStartBtnPress(step)} disabled={!online}>
                          {t('my-visits.start-button')}
                        </Button>
                      ) : null}
                    </CardActions>
                  </Card>
                ))}
              </List>
            </>
          )}
        </LoadingOverlay>
      </div>
    </Paper>
  );
};

export default FieldVisits;
