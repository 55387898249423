import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteFieldVisitWithDexie,
  getAllFieldVisitsWithDexie,
  getFieldByProtocolWithDexie,
  getFieldVisitWithDexie,
  updateFieldWithDexie
} from '@services/local';
import { setSyncEnabled } from '@state-mgmt/slices/appSlice';
import { useAsyncCallback } from '@utils/useAsyncCallback';

export const useRemoveLocalFieldVisitAsync = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (localVisitId: number) => {
      const visit = await getFieldVisitWithDexie(localVisitId);
      if (!visit) return;
      await deleteFieldVisitWithDexie(localVisitId);

      if (visit) {
        const localField = await getFieldByProtocolWithDexie(visit.fieldId, visit.protocolId);
        if (localField && localField.visits && Array.isArray(localField?.visits)) {
          const visits = localField.visits.map(v => {
            if (v.localId === localVisitId) {
              return {
                ...v,
                localId: null
              };
            } else return v;
          });
          await updateFieldWithDexie(localField.id, { visits });
        }

        const localVisits = await getAllFieldVisitsWithDexie();
        dispatch(setSyncEnabled(localVisits.length ? true : false));
      }
    }, [])
  );
};
