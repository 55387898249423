import axios from 'axios';
import { CreateHelpDeskTicketRequesPayload, CreateHelpDeskTicketResponsePayload, SignInRequestPayload, SignInResponsePayload } from '@mytypes/token';

import config from '../config';

const apiUrl = config.REACT_APP_API_URL;

export const signInApi = async (payload: SignInRequestPayload): Promise<SignInResponsePayload | undefined> => {
  try {
    const response = await fetch(`${apiUrl}/signin`, {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    if (response.status === 401) {
      return { error: true };
    }
  } catch (error) {
    console.error(error);
  }
};

export const refreshTokenApi = async (): Promise<void> => {
  try {
    const response = await axios.post(`${apiUrl}/refresh`, {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken')
    });
    if (response.status === 200) {
      const { accessToken, refreshToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
  } catch (error: any) {
    if (error.response) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    }
  }
};

export const createHelpDeskTicket = async (payload: CreateHelpDeskTicketRequesPayload): Promise<CreateHelpDeskTicketResponsePayload | undefined> => {
  try {
    const { data } = await axios.post(`${apiUrl}/help`, {
      accessToken: localStorage.getItem('accessToken'),
      message: payload.message,
      clientName: payload.clientName,
      trialName: payload.trialName,
      uploadsUrls: payload.uploadsUrls
    });
    return data;
  } catch (error: any) {
    console.error(error);
  }
};
